<template>
    <!-- 导航 -->
    <page-head title="教案详情" />
    <content-bg>
        <template v-slot:content>
            <div class="mian">
                <exerciseLeft :list="detailsData" @preview-click="previewClick" />
                <div class="main_right">
                    <div class="head">
                        <h2 class="title">{{ detailsData.title }}</h2>
                        <p v-if="detailsData.createTime" class="date">
                            创建日期：<span class="akrobatRegular">{{ $moment(new Date(detailsData.createTime)).format('YYYY/MM/DD HH:mm:ss') }}</span>
                        </p>
                    </div>
                    <div v-if="detailsData.tags" class="tags">
                        <tags
                            v-for="(item, index) in detailsData.tags"
                            :key="index"
                            :item="item"
                        />
                    </div>
                    <div class="info">
                        <h4 class="label">目标</h4>
                        <p class="content">
                            {{ detailsData.goal }}
                        </p>
                    </div>
                    <div class="info">
                        <h4 class="label">内容</h4>
                        <p class="content">
                            <pre>{{ detailsData.desc }}</pre>
                        </p>
                    </div>
                    <div class="info">
                        <h4 class="label">附件</h4>
                        <p class="content">
                            <div class="flex_container">
                                <div v-for="(item, index) in detailsData.files" :key="index" class="file_item">
                                    <img class="icon" :src="require(`@i/exercise/tu_file_${$utils.formatSuffix(item.name)}.svg`)" />
                                    <div class="text">
                                        <p class="name" style="max-width:100% !important">{{item.name}}</p>
                                        <p class="size akrobatRegular">{{item.size}}</p>
                                    </div>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </template>
    </content-bg>
    <!-- 图片/动画弹窗 -->
    <exerciseDialog ref="exerciseDialogRef" />
</template>

<script>
import { useRoute } from "vue-router";
import { getCurrentInstance, reactive, toRefs, onMounted, defineComponent } from "vue";
import exerciseLeft from "./components/exerciseLeft.vue"
import exerciseDialog from "./components/exerciseDialog.vue"

export default defineComponent({
    components: {
        exerciseLeft,
        exerciseDialog
    },
    setup() {
        const route = useRoute();
        const { proxy } = getCurrentInstance()
        const state = reactive({
            detailsData: {},
            exerciseDialogRef: null,
        });

        const getDetailsData = async () => {
            const params = {
                exerciseId: route.query.id
            }
            const { code, data } = await proxy.$server.getExerciseDetail(params);
            if (code === 200) {
                state.detailsData = data;
            }
        };

        // 图片/视频
        const previewClick = (type) => {
            const params = {
                fileType: type,
                previewData: state.detailsData
            }
            state.exerciseDialogRef.initFn(params);
        }

        onMounted(() => {
            getDetailsData()
        })

        return {
            previewClick,
            ...toRefs(state),
        }
    }
})
</script>

<style scoped lang="scss">
.mian {
    display: flex;
    flex-wrap: wrap;
    padding: 44px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .main_right {
        flex: 1;
        height: 696px;
        overflow: hidden;
        overflow-y: auto;
        color: #fff;
        .head {
            margin-bottom: 24px;
            .title {
                width: 100%;
                margin-bottom: 12px;
                font-weight: 600;
                font-size: 32px;
                line-height: 45px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .date {
                font-weight: 400;
                font-size: 22px;
                line-height: 31px;
                opacity: 0.6;
            }
        }
        .tags {
            margin-bottom: 60px;
        }
        .info {
            margin-bottom: 32px;
            font-size: 24px;
            line-height: 34px;
            .label {
                font-weight: 600;
                margin-bottom: 16px;
            }
            .content {
                opacity: 0.8;
                .flex_container {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .file_item {
                        padding: 5px 12px;
                        width: 280px;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin-right: 16px;
                        border: 1px solid #EDEDF4;
                        border-radius: 6px;
                        box-sizing: border-box;
                        .icon {
                            margin-right: 6px;
                            width: 80px;
                            height: 100px;
                        }
                        .text {
                            flex: 1;
                            .name {
                                width: 190px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 20px;
                            }
                            .size {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>