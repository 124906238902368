<template>
    <div class="dialog" v-show="isShow">
        <div class="model" @click="closeDialog"></div>
        <div class="dialog_box">
            <div class="head">
                <p class="title">{{ previewData.title || '--' }}</p>
                <p class="akrobatRegular">
                    {{$moment(previewData.createTime).format('YYYY/MM/DD')}}
                </p>
            </div>
            <div class="content" :style="{'background': previewData.backgroundColor === 'null' || !previewData.backgroundColor ? '#3CA26B' : previewData.backgroundColor}">
                <div class="view_left">
                    <!-- 视频 -->
                    <video
                        v-if="fileType === 'video' && previewData.videoUrl"
                        :src="previewData.videoUrl"
                        controls
                        controlslist="nodownload noplaybackrate"
                        oncontextmenu="return false"
                        disablepictureinpicture
                        autoplay
                    />
                    <no-data v-if="fileType === 'video' && !previewData.videoUrl" text="暂无动画" />
                    <!-- 图片 -->
                    <img
                        v-if="fileType === 'image'"
                        :src="previewData.drawingThumbnail || require('@i/exercise/formation_bg.svg')"
                    />
                </div>
                <!-- notes -->
                <div class="notes_box">
                    <img class="icon" :src="require('@i/exercise/exercise_notes.png')" />
                    <div class="notes">
                        <pre>{{ previewData.notes }}</pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
    setup() {
        const state = reactive({
            isShow: false,
            previewData: {},
            fileType: ''
        });

        const initFn = (row) => {
            state.isShow = true
            state.previewData = row.previewData
            state.fileType = row.fileType
            console.log(row)
        }

        const closeDialog = () => {
            state.isShow = false
            state.previewData = {}
            state.fileType = ''
        }

        return {
            initFn,
            closeDialog,
            ...toRefs(state),
        }
    }
})
</script>

<style lang="scss" scoped>
.dialog {
    position: initial;
    .model {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(15,15,23,0.5);
        z-index: 99;
    }
    .dialog_box {
        position: absolute;
        width: 1080px;
        height: 980px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 12px;
        padding: 26px;
        box-sizing: border-box;
        z-index: 999;
        .head {
            margin-bottom: 14px;
            .title {
                margin-bottom: 5px;
                font-size: 22px;
                line-height: 32px;
                color: #3A3A3A;
            }
            .akrobatRegular {
                font-weight: 600;
                font-size: 22px;
                color: #B7B7BF;
            }
        }
        .content {
            width: 100%;
            height: 847px;
            display: flex;
            padding-left: 22px;
            box-sizing: border-box;
            border-radius: 7px;
            .view_left {
                padding: 12px;
                border-left: 1px dashed rgba(37, 131, 80, 0.25);
                border-right: 1px dashed rgba(37, 131, 80, 0.25);
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1;
                video {
                    width: 600px;
                    outline: none;
                }
                img{
                    width: 600px;
                }
            }
            .notes_box {
                padding: 22px;
                width: 360px;
                box-sizing: border-box;
                .icon {
                    width: 74px;
                    margin-bottom: 15px;
                }
                .notes {
                    padding: 12px 16px;
                    width: 300px;
                    height: 750px;
                    background: url('../../../assets/images/exercise/notes_background_normal.png');
                    background-size: 100% 100%;
                    border-radius: 4px;
                    overflow: auto;
                    box-sizing: border-box;
                    font-size: 14px;
                    line-height: 24px;
                    color: #fff;
                }
            }
            // 音量按钮
            video::-webkit-media-controls-volume-control-container {
                display: none !important;
            }
            // 全屏
            video::-webkit-media-controls-fullscreen-button {
                display: none !important;
            }
            // 更多选项 --然而并不生效
            video::-internal-media-controls-overflow-button {
                display: none !important;
            }
            // 更多选项
            video::-webkit-media-controls:not(.audio-only) input[pseudo="-webkit-media-controls-timeline" i]::-internal-track-segment-highlight-before  {
                display: none !important;
            }
        }
    }
}
</style>
