<template>
    <div class="main_left">
        <div class="img_box">
            <div class="img_con">
                <!-- 教案 -->
                <div v-if="switchType === 'image'">
                    <img
                        :src="practice.drawingThumbnail ? practice.drawingThumbnail : require('@i/exercise/formation_bg.svg')"
                        alt
                        @click="animationPlay"
                    />
                </div>
                <!-- 动画教案 -->
                <div v-if="switchType === 'video'" class="img_con_video">
                    <img
                        v-if="practice.videoUrl && practice.videoSnapshot"
                        :src="practice.videoSnapshot"
                    />
                    <img v-else src="@i/exercise/formation_bg.svg" alt />
                    <!-- 播放按钮 -->
                    <div class="video_layer">
                        <img
                            src="@i/exercise/video_play.svg"
                            alt
                            @click="animationPlay"
                        />
                    </div>
                </div>
            </div>
            <div class="img_switch">
                <div
                    :class="{active: switchType === 'image'}"
                    @click="switchType = 'image'"
                >
                    <img
                        v-if="switchType === 'image'"
                        :src="require('@i/exercise/exercise_image_active.svg')"
                        alt
                    />
                    <img v-else :src="require('@i/exercise/exercise_image.svg')" alt />
                </div>
                <div
                    :class="{active: switchType === 'video'}"
                    @click="switchType = 'video'"
                >
                    <img
                        v-if="switchType === 'video'"
                        :src="require('@i/exercise/exercise_animation_active.svg')"
                        alt
                    />
                    <img v-else :src="require('@i/exercise/exercise_animation.svg')" alt />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, watch, getCurrentInstance } from 'vue';
export default defineComponent({
    props: {
        list: {
            type: Object,
            default: () => { }
        },
    },
    emits: ['preview-click'],
    setup(props, { emit }) {
        const { proxy } = getCurrentInstance()
        const { color } = proxy.$Config.UI_CONFIG
        const state = reactive({
            color,
            practice: {},
            switchType: 'image', // 查看图片或者动画
        });
        watch(
            () => props.list,
            (newVal) => {
                if (newVal) {
                    state.practice = newVal;
                }
            }
        );

        const animationPlay = () => {
            const type = state.switchType
            // if (!state.practice.drawingThumbnail && type == 'img') {
            //     alert("暂无图片")
            //     return
            // }
            // if ((!state.practice.videoUrl || !state.practice.videoSnapshot) && type == 'video') {
            //     alert("暂无动画")
            //     return
            // }
            emit('preview-click', type)
        }


        return {
            animationPlay,
            ...toRefs(state),
        }
    }
})
</script>
<style lang="scss" scoped>
.main_left {
    width: 528.8px;
    margin-right: 49px;
    border-radius: 6px;
    background: v-bind("color");
    .img_box {
        height: 696px;
        position: relative;
        .img_con {
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
            &_video {
                height: 100%;
                position: relative;
                .video_layer {
                    top: 0;
                    left: 0;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background: rgba($color: #2f2222, $alpha: 0.24);
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    img {
                        height: 48px;
                        width: 48px;
                    }
                }
            }
        }
        .img_switch {
            display: flex;
            align-items: center;
            width: 238px;
            height: 72px;
            box-sizing: border-box;
            background: rgba($color: #000000, $alpha: 0.32);
            border-radius: 54px;
            position: absolute;
            bottom: 36px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 8px;
            justify-content: space-between;
            & > div {
                height: 56px;
                width: 108px;
                border-radius: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    width: 36px;
                    height: 36px;
                }
                &.active {
                    background: #FBFDFF;
                }
            }
        }
        // .view_draft {
        //     position: absolute;
        //     top: 0;
        //     left: -10px;
        // }
    }
}
</style>
